import React from 'react';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import {Button, TextField, Checkbox, FormControlLabel} from '@mui/material';
import './LoginForm.scss';
import {useAuth} from '../../hooks/useAuth';

const validationSchema = Yup.object({
    email: Yup.string().required('This field must be filled in.'),
    password: Yup.string().required('This field must be filled in.'),
});

const LoginForm: React.FC = () => {
    const {login} = useAuth();

    const formik = useFormik({
        initialValues: {
            email: '',
            password: '',
            rememberMe: false,
        },
        validationSchema,
        onSubmit: async (values) => {
            await login(values.email, values.password, values.rememberMe);
        },
    });

    return (
        <form onSubmit={formik.handleSubmit} className="login-form">
            <div className="text-field">
                <TextField
                    fullWidth
                    id="email"
                    name="email"
                    label="email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                />
            </div>
            <div className="text-field">
                <TextField
                    fullWidth
                    id="password"
                    name="password"
                    label="Password"
                    type="password"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    error={formik.touched.password && Boolean(formik.errors.password)}
                    helperText={formik.touched.password && formik.errors.password}
                />
            </div>
            <FormControlLabel
                control={<Checkbox name="rememberMe" checked={formik.values.rememberMe}
                                   onChange={formik.handleChange}/>}
                label="Remember me"
            />
            <Button color="primary" variant="contained" fullWidth type="submit">
                Login
            </Button>
        </form>
    );
};

export default LoginForm;
