import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { AuthProvider } from './context/AuthContext';
import ResponsiveAppBar from './components/AppBar/ResponsiveAppBar';
import Login from './pages/Login';
import { Teams } from './pages/Teams';
import Employees from './pages/Employees';
import ProtectedRoute from './routes/ProtectedRoute';
import WorkOrdersPage from './pages/WorkOrdersPage';
import WorkOrderForm from './components/WorkOrders/WorkOrderForm';
import UserForm from './components/Users/UserForm'; // Import the user form

const App: React.FC = () => {
    return (
        <AuthProvider>
            <ResponsiveAppBar />
            <Routes>
                <Route path="/login" element={<Login />} />
                <Route element={<ProtectedRoute />}>
                    {/* Work Orders Routes */}
                    <Route path="/workorders" element={<WorkOrdersPage />} />
                    <Route path="/workorders/new" element={<WorkOrderForm />} />
                    <Route path="/workorders/:id" element={<WorkOrderForm />} />

                    {/* Teams Route */}
                    <Route path="/teams" element={<Teams />} />

                    {/* Employees Routes */}
                    <Route path="/employees" element={<Employees />} />
                    <Route path="/users/new" element={<UserForm />} /> {/* Route to create a new user */}
                    <Route path="/users/:id" element={<UserForm />} /> {/* Route to view/edit a user */}
                </Route>

                <Route path="/" element={<Navigate to="/login" />} />
            </Routes>
        </AuthProvider>
    );
};

export default App;
