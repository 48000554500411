import React from 'react';
import TeamLocation from '../components/TeamLocation/TeamLocation'; // Adjusted import

export const Teams: React.FC = () => {
    return (
        <div>
            <h1>Teams Page</h1>
            <TeamLocation />
        </div>
    );
};
