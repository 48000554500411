import { createSlice } from '@reduxjs/toolkit';
import { loginThunk, logoutThunk } from './authThunks';
import { User } from '../user/userSlice';
import { Socket } from 'socket.io-client';

let socket: Socket | null = null;

interface AuthState {
    token: string | null;
    user: User | null; // Add user property
    status: 'idle' | 'loading' | 'succeeded' | 'failed';
    error: string | null;
}

const initialState: AuthState = {
    token: localStorage.getItem('token') || sessionStorage.getItem('token'),
    user: null, // Initialize user as null
    status: 'idle',
    error: null,
};

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        // Logout reducer to handle direct logout actions
        logout: (state) => {
            // Clear token from storage
            localStorage.removeItem('token');
            sessionStorage.removeItem('token');

            // Disconnect WebSocket if open
            if (socket) {
                socket.disconnect();
                socket = null;
            }

            state.user = null;
            state.token = null;
            state.status = 'idle';
            state.error = null;
        },
    },
    extraReducers: (builder) => {
        builder
            // Handle login thunk
            .addCase(loginThunk.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(loginThunk.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.token = action.payload.token; // Set the token
                state.user = action.payload.user; // Set the user data
                state.error = null;
            })
            .addCase(loginThunk.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload as string;
            })

            // Handle logout thunk (backend-based logout)
            .addCase(logoutThunk.fulfilled, (state) => {
                // Clear token and user data on logout
                state.token = null;
                state.user = null; // Clear user data on logout
                state.status = 'idle';
                state.error = null;

                // Disconnect WebSocket if open
                if (socket) {
                    socket.disconnect();
                    socket = null;
                }
            });
    },
});

export const { logout } = authSlice.actions;
export default authSlice.reducer;
