// src/redux/auth/authThunks.ts
import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../../api/axiosConfig'; // Axios instance with interceptor
import { io, Socket } from 'socket.io-client';

let socket: Socket | null = null;

// Login Thunk with WebSocket initialization for EXECUTOR_ADMINISTRATOR
export const loginThunk = createAsyncThunk(
    'auth/login',
    async (
        { email, password, rememberMe }: { email: string; password: string; rememberMe: boolean }
    ) => {
        try {
            const response = await axiosInstance.post('/auth/login', {
                email,
                password,
                rememberMe,
            });
            const { access_token, user } = response.data;

            // Store token in localStorage or sessionStorage based on rememberMe
            if (rememberMe) {
                localStorage.setItem('token', access_token);
            } else {
                sessionStorage.setItem('token', access_token);
            }

            // If user is EXECUTOR_ADMINISTRATOR, initiate WebSocket connection
            if (user.role === 'EXECUTOR_ADMINISTRATOR') {
                socket = io('https://api.alcovrach.tech', {
                    query: { token: access_token }, // Send token with the WebSocket query
                    transports: ['websocket'],
                    withCredentials: true,
                });

                socket.on('connect', () => {
                    console.log('WebSocket connected for location tracking');

                    // Send initial location
                    navigator.geolocation.getCurrentPosition((position) => {
                        const { latitude, longitude } = position.coords;
                        socket?.emit('sendLocation', { latitude, longitude });
                    });

                    // Periodically send location updates every 10 seconds
                    const intervalId = setInterval(() => {
                        navigator.geolocation.getCurrentPosition((position) => {
                            const { latitude, longitude } = position.coords;
                            if (socket) {
                                socket.emit('sendLocation', { latitude, longitude });
                            }
                        });
                    }, 10000);

                    // Handle WebSocket disconnect
                    socket?.on('disconnect', () => {
                        console.log('WebSocket disconnected');
                        clearInterval(intervalId); // Clear the interval when disconnected
                    });
                });

                // Listen for location updates from the server
                socket.on('locationUpdate', (data) => {
                    console.log('Location update received:', data);
                });
            }

            return { token: access_token, user }; // Return token and user data
        } catch (error) {
            throw new Error('Login failed');
        }
    }
);

// Logout Thunk with WebSocket disconnection
export const logoutThunk = createAsyncThunk('auth/logout', async () => {
    // Clear token from localStorage or sessionStorage
    localStorage.removeItem('token');
    sessionStorage.removeItem('token');

    // Disconnect WebSocket if connected
    if (socket) {
        socket.disconnect();
        socket = null;
        console.log('WebSocket disconnected on logout');
    }

    return; // No specific return value needed for logout
});
