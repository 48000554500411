import React, { useEffect, useState } from 'react';
import { io, Socket } from 'socket.io-client';
import YandexMap from './YandexMap';

interface ExecutorLocation {
    email: string;
    latitude: number;
    longitude: number;
}

const LocationTracker: React.FC = () => {
    const [socket, setSocket] = useState<Socket | null>(null);
    const [executorLocations, setExecutorLocations] = useState<ExecutorLocation[]>([]);
    const [mapCenter, setMapCenter] = useState<[number, number]>([53.9, 27.5667]); // Default Minsk
    const [zoom, setZoom] = useState(10);

    const getAuthToken = (): string | null => {
        return localStorage.getItem('token') || sessionStorage.getItem('token');
    };

    useEffect(() => {
        const authToken = getAuthToken();
        if (!authToken) {
            console.error('No token found');
            return;
        }

        const newSocket = io('https://api.alcovrach.tech', {
            query: { token: authToken },
            transports: ['websocket'],
            withCredentials: true,
        });

        newSocket.on('connect', () => {
            console.log('WebSocket connected:', newSocket.id);
        });

        newSocket.on('disconnect', (reason) => {
            console.log('WebSocket disconnected:', reason);
        });

        newSocket.on('locationUpdate', (updatedLocation: ExecutorLocation) => {
            setExecutorLocations((prevLocations) => {
                const existingLocationIndex = prevLocations.findIndex(loc => loc.email === updatedLocation.email);
                if (existingLocationIndex >= 0) {
                    const updatedLocations = [...prevLocations];
                    updatedLocations[existingLocationIndex] = updatedLocation;
                    return updatedLocations;
                } else {
                    return [...prevLocations, updatedLocation];
                }
            });

            setMapCenter([updatedLocation.latitude, updatedLocation.longitude]);
            setZoom(12); // Optional zoom level
            console.log('Received updated location:', updatedLocation);
        });

        setSocket(newSocket);

        return () => {
            newSocket.disconnect();
        };
    }, []);

    useEffect(() => {
        if (socket) {
            const watchId = navigator.geolocation.watchPosition((position) => {
                const { latitude, longitude } = position.coords;
                console.log("Sending location to server:", latitude, longitude);
                socket.emit('sendLocation', { latitude, longitude });
            });

            return () => {
                navigator.geolocation.clearWatch(watchId);
            };
        }
    }, [socket]);

    return (
        <div>
            <h2>Teams Map</h2>
            <YandexMap center={mapCenter} zoom={zoom} executorLocations={executorLocations} />
        </div>
    );
};

export default LocationTracker;
